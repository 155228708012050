import React from 'react';
import logo from './logo.png';
import { Link } from "react-router-dom";


function Login() {
    return (
        <div className="App">
        <header className="App-header">
            <br/>
            <Link to="/"><img src={logo} className="App-logo" alt="logo"/></Link>     
            <p>
                This page has not been created, yet!
            </p>
        </header>        
      </div>
    );
  }

export default Login;