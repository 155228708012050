import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import logo from './logo.png';

//Import CSS
import './App.css';

//Imported Components used
import Section from "./Components/Section";
import Navbar from "./Components/Navbar";
import Login from "./Login"

// Text Copy for the FE
import homeCopy from "./Copy/Home.js";
import aboutUsCopy from "./Copy/AboutUs.js";
import experienceCopy from "./Copy/Experience.js";
import automationCopy from "./Copy/Automation.js";
import contactCopy from "./Copy/Contact.js";

export default function App() {
  return (
    <Router>
      <div>

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="/">
            <div className="App">
              <Navbar />
              <header className="App-header">
                  <br/>
                  <Link to="/"><img src={logo} className="App-logo" alt="logo"/></Link>     
                  <p>
                      Find out more below
                  </p>
              </header>
              <body>
              <Section className="section-top"
                title="Welcome to One QA"
                subtitle={homeCopy}
                dark={true}
                id="section1"
              />
              <Section
                title="Testing, streamlined"
                subtitle={aboutUsCopy}
                dark={false}
                id="section2"
              />
              <Section
                title="Experience"
                subtitle={experienceCopy}
                dark={true}
                id="section3"
              />
              <Section
                title="Automation"
                subtitle={automationCopy}
                dark={false}
                id="section4"
              />
              <Section
                title="Contact Us"
                subtitle={contactCopy}
                dark={true}
                id="section5"
              />   
              </body>     
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
};